<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-sm">
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="series"
        ></apexchart>
        <q-select
          dense
          :options="$LIST_JENJANG"
          v-model="selJenjang"
          outlined
          @input="
            selTujuan = 'SEMUA SEKOLAH';
            selLulusan = 'SEMUA TAHUN';
            fetch();
          "
          label="Jenjang"
        ></q-select>
        <q-select
          dense
          :options="lulusan"
          v-model="selLulusan"
          outlined
          @input="
            selTujuan = 'SEMUA SEKOLAH';
            getJenjangTertentu();
            fetchTable();
          "
          label="Tahun Lulusan"
        ></q-select>
        <q-select
          dense
          :options="tujuan"
          v-model="selTujuan"
          outlined
          @input="fetchTable()"
          label="Sekolah Tujuan"
        ></q-select>
      </q-card-section>
      <q-markup-table
        flat
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 250px)"
      >
        <thead>
          <th>Nama</th>
          <th>Tahun Keluar</th>
          <th>Sekolah Tujuan</th>
        </thead>
        <tbody>
          <tr v-for="(val, i) in siswa" :key="i">
            <td>{{ val.nama }}</td>
            <td>{{ val.tahun_keluar }}</td>
            <td>{{ val.sekolah_tujuan }}</td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="center">
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          @input="fetchTable"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      selJenjang: "",
      addedOpt: [],
      siswaAll: [],
      siswa: [],
      tujuan: [],
      selTujuan: "",

      lulusan: [],
      selLulusan: "SEMUA TAHUN",

      series: [],
      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
      chartOptions: {
        labels: [],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          },
        },

        chart: {
          type: "donut",
        },
      },
    };
  },
  created() {
    this.prepareOption();
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.fetch();
    await this.fetchTable();
    this.$q.loading.hide();
    // this.onRequest({ pagination: this.initialPagination, filter: this.filter });
  },

  methods: {
    async getSiswa() {
      let resp = await this.$http.get(
        `/statistik/alumni/getsiswa/${this.selJenjang}`
      );
      for (let item of resp.data) {
        if (item.sekolah_tujuan == null) {
          item.sekolah_tujuan = "TIDAK LANJUT";
        }
      }
      this.siswaAll = resp.data;
    },
    async fetchTable() {
      let temp = JSON.parse(JSON.stringify(this.siswaAll));
      // let disTujuan = [...new Set(siswaAll.map(x => x.sekolah_tujuan))];

      if (this.selLulusan != "SEMUA TAHUN") {
        temp = temp.filter((x) => {
          return x.tahun_keluar == this.selLulusan;
        });
      }

      if (
        this.selTujuan != "SEMUA SEKOLAH" &&
        this.selTujuan != "DROP OUT" &&
        this.selTujuan != "PINDAH"
      ) {
        temp = temp.filter((x) => {
          return x.sekolah_tujuan == this.selTujuan;
        });
      }

      if (this.selTujuan == "DROP OUT") {
        temp = temp.filter((x) => {
          return x.status == "DROP OUT";
        });
      }

      if (this.selTujuan == "PINDAH") {
        temp = temp.filter((x) => {
          return x.status == "PINDAH";
        });
      }

      let ret = [];
      for (let i = 0; i < 10; i++) {
        if (temp[(this.pagination.current - 1) * 10 + i]) {
          ret.push(temp[(this.pagination.current - 1) * 10 + i]);
        }
      }

      this.pagination.max = Math.ceil(temp.length / 10);

      this.siswa = ret;
    },
    prepareOption() {
      this.selJenjang = this.$LIST_JENJANG[0];
      this.selTujuan = "SEMUA SEKOLAH";
    },
    async fetch() {
      await this.getJenjangTertentu();
      await this.getTujuan();
      await this.getLulusan();
      await this.getSiswa();
    },
    async getJenjangTertentu() {
      let chartopt = this.MCopyObject(this.chartOptions);
      chartopt.labels = [];
      let tempSeries = [];

      let url = `/statistik/alumni/gettop/${this.selJenjang}/${this.selLulusan}`;
      let resp = await this.$http.get(url, this.MGetHeader());
      let top = resp.data;
      for (let el of top) {
        chartopt.labels.push(el.sekolah_tujuan);
        tempSeries.push(parseInt(el.banyak));
      }

      url = `/statistik/alumni/getnull/${this.selJenjang}/${this.selLulusan}`;
      resp = await this.$http.get(url, this.MGetHeader());
      chartopt.labels.push("Tidak Lanjut");
      tempSeries.push(parseInt(resp.data.banyak));

      url = `/statistik/alumni/getspecify/${this.selJenjang}/${this.selLulusan}/DROP OUT`;
      resp = await this.$http.get(url, this.MGetHeader());
      chartopt.labels.push("DROP OUT");
      tempSeries.push(parseInt(resp.data.banyak));

      url = `/statistik/alumni/getspecify/${this.selJenjang}/${this.selLulusan}/PINDAH`;
      resp = await this.$http.get(url, this.MGetHeader());
      chartopt.labels.push("PINDAH");
      tempSeries.push(parseInt(resp.data.banyak));

      url = `/statistik/alumni/getnot/${this.selJenjang}/${this.selLulusan}`;
      resp = await this.$http.post(url, top, this.MGetHeader());
      chartopt.labels.push("Lainya");
      tempSeries.push(parseInt(resp.data.banyak));

      this.chartOptions = chartopt;
      this.series = tempSeries;
    },
    async getTujuan() {
      this.tujuan = [];
      let resp = null;

      let url = `/statistik/alumni/gettujuan/${this.selJenjang}`;
      resp = await this.$http.get(url, this.MGetHeader());

      for (let el of resp.data) {
        this.tujuan.push(el.sekolah_tujuan);
      }

      this.tujuan.unshift("DROP OUT");
      this.tujuan.unshift("PINDAH");
      this.tujuan.unshift("TIDAK LANJUT");
      this.tujuan.unshift("SEMUA SEKOLAH");
    },
    async getLulusan() {
      let lulusan = [];

      let resp = await this.$http.get(
        `/statistik/alumni/getlulusan/${this.selJenjang}`
      );
      for (let item of resp.data) {
        lulusan.push(item.tahun_keluar);
      }
      lulusan.unshift("SEMUA TAHUN");
      this.lulusan = lulusan;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.scss";
</style>
